import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../../lawyerinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from 'src/app/alert/alert.component';

@Component({
  selector: 'app-advocate',
  templateUrl: './advocate.component.html',
  styleUrls: ['./advocate.component.css']
})
export class AdvocateComponent implements OnInit {

  
  advocatedata:any = [];
  advocatemodal:any = [];
  code_no: any;
  name: any;
  phone: any;
  fsd:any;


  errorMessage: string;
  isLoading: boolean = true;
  isLoadingImage: boolean = true;

  constructor(
      public rest:LawyerinfoService, 
      private route: ActivatedRoute, 
      private router: Router,
      private SimpleModalService: SimpleModalService
  ) { }




  ngOnInit() {
    this.getadvocatealllist();

    
    window.scrollTo({
      top: 1350,
      left: 0, 
      behavior: 'smooth'
    });

  }

  getadvocatealllist() {
    let postData = {
      code_no: this.code_no,
      name: this.name,
      phone: this.phone,
    }
    //console.log(postData);

    this.rest
        .getadvocatealllist(postData)
        .subscribe(
          advocatedata => {
           // console.log(courtwisedata);
              this.advocatedata = advocatedata
              this.isLoading = false
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }



  showAlert2() {
    this.SimpleModalService.addModal(AlertComponent, {title: 'Alert title!', message: 'Alert message!!!'});
  }

  showAlert(l_id) {
    let postData = {
      l_id: l_id,
    }

    this.rest
        .showAlert(postData)
        .subscribe(
          advocatemodal => {
            //console.log(advocatemodal[0].lawyer_name);
                this.advocatemodal = advocatemodal
                this.isLoading = false
                this.SimpleModalService.addModal(AlertComponent, {title: 'LAWYER INFORMATION', message: 'Code No : ' + advocatemodal[0].lawyer_id + "\n" + 'Name : ' + advocatemodal[0].lawyer_name + "\n\r" + 'Address : ' + advocatemodal[0].address + "\n\r" });
            },
            error => {
                this.errorMessage = <any>error
                this.isLoading = false
            }
        );
  }


}
